// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery/dist/jquery.min.js
//= require bootstrap/dist/js/bootstrap.min.js
//= require admin-lte/dist/js/adminlte.min.js

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "select2"
import "anychart"

// import "jquery"
var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// import ActionText
var Trix = require("trix")
require("@rails/actiontext")


// Trix リッチテキストエディタ 追加設定
Trix.config.textAttributes.red = {
  style: {
    color: "red"
  },
  parser: function(element) {
    return element.style.color === "red"
  },
  inheritable: true
}

Trix.config.textAttributes.blue = {
  style: {
    color: "blue"
  },
  parser: function(element) {
    return element.style.color === "blue"
  },
  inheritable: true
}

Trix.config.textAttributes.green = {
  style: {
    color: "green"
  },
  parser: function(element) {
    return element.style.color === "green"
  },
  inheritable: true
}

Trix.config.textAttributes.springgreen = {
  style: {
    color: "springgreen"
  },
  parser: function(element) {
    return element.style.color === "springgreen"
  },
  inheritable: true
}

Trix.config.textAttributes.dimgray = {
  style: {
    color: "dimgray"
  },
  parser: function(element) {
    return element.style.color === "dimgray"
  },
  inheritable: true
}

Trix.config.textAttributes.gray = {
  style: {
    color: "gray"
  },
  parser: function(element) {
    return element.style.color === "gray"
  },
  inheritable: true
}

Trix.config.textAttributes.silver = {
  style: {
    color: "silver"
  },
  parser: function(element) {
    return element.style.color === "silver"
  },
  inheritable: true
}

Trix.config.textAttributes.white = {
  style: {
    color: "white"
  },
  parser: function(element) {
    return element.style.color === "white"
  },
  inheritable: true
}

Trix.config.textAttributes.orange = {
  style: {
    color: "orange"
  },
  parser: function(element) {
    return element.style.color === "orange"
  },
  inheritable: true
}

Trix.config.textAttributes.orangered = {
  style: {
    color: "orangered"
  },
  parser: function(element) {
    return element.style.color === "orangered"
  },
  inheritable: true
}

Trix.config.textAttributes.magenta = {
  style: {
    color: "magenta"
  },
  parser: function(element) {
    return element.style.color === "magenta"
  },
  inheritable: true
}

Trix.config.textAttributes.blueviolet = {
  style: {
    color: "blueviolet"
  },
  parser: function(element) {
    return element.style.color === "blueviolet"
  },
  inheritable: true
}

Trix.config.textAttributes.deepskyblue = {
  style: {
    color: "deepskyblue"
  },
  parser: function(element) {
    return element.style.color === "deepskyblue"
  },
  inheritable: true
}

Trix.config.textAttributes.yellowgreen = {
  style: {
    color: "yellowgreen"
  },
  parser: function(element) {
    return element.style.color === "yellowgreen"
  },
  inheritable: true
}

Trix.config.textAttributes.yellow = {
  style: {
    color: "yellow"
  },
  parser: function(element) {
    return element.style.color === "yellow"
  },
  inheritable: true
}

Trix.config.textAttributes.peru = {
  style: {
    color: "peru"
  },
  parser: function(element) {
    return element.style.color === "peru"
  },
  inheritable: true
}